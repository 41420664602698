import { gsap, TweenLite, Power1 } from "gsap";

document.addEventListener('DOMContentLoaded',function() {
            
    const el = document.getElementById('harmonogram');
    
    const init = function() {

        const day = el.getElementsByClassName('js-day');        

        for (let i = 0; i < day.length; i++) {

            const btn = day[i].getElementsByClassName('js-showAgenda')[0],
                  article = day[i].getElementsByClassName('js-article') ;
            
            btn.addEventListener('click', function() {                
                if (day[i].classList.contains('is-open')) {
                    for (let j = 0; j < article.length; j++) {
                        TweenLite.to(article[j], 0.4, {
                            height: 0,
                            opacity: 0
                        })
                    }
                    day[i].classList.remove('is-open');

                } else {
                    for (let j = 0; j < article.length; j++) {
                        TweenLite.set(article[j], { 
                            height: "auto",
                            opacity: 1
                        })
                        TweenLite.from(article[j], 0.4, {
                            height: 0,
                            opacity: 0
                        })
                    }
                    day[i].classList.add('is-open');
                }
            });
        }
    };
    
    el ? init() : false;

}, false);
